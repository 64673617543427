import axios from 'axios'
import { EnvironmentService } from '../libs/environment'

const { TMTS_API_URL } = EnvironmentService.getVars()

let TOKEN: string = ''

function _getAxiosConfig() {
  return {
    headers: {
      Authorization: `Bearer ${TOKEN}`
    }
  }
}

function getProfessionalRequests(facilityId: string | null) {
  return axios
    .get(`${TMTS_API_URL}/v1/admin/professional_requests?facility=${facilityId}`, _getAxiosConfig())
    .then(res => {
      if (res.data) { // filtering QA trash
        res.data = res.data.filter((el: any) => el.user !== null)
      }

      return res
    })
}

function patchProfessionalRequests(requestId: string, status: string) {
  return axios.patch(`${TMTS_API_URL}/v1/admin/professional_requests/${requestId}`, { status: status.toUpperCase() }, _getAxiosConfig())
}

export function useTMTS(token: string) {
  TOKEN = token

  return {
    getProfessionalRequests,
    patchProfessionalRequests
  }
}