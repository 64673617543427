import React from 'react'
import { BrowserRouter, Navigate, Route, Routes, useSearchParams } from 'react-router-dom'

import Home from '../pages/Home'

import { ACLProvider } from '../contexts/acl'
import { useAuth } from '../contexts/auth'
import { ACLService } from '../libs/acl'
import { EnvironmentService } from "../libs/environment"
import SetLanguage from "../pages/SetLanguage"

const AuthorizedRoutes: React.FC = () => {
  const { tokens } = useAuth()

  const { TCC_API_URL } = EnvironmentService.getVars()

  const TM_ACL_ENDPOINT = `${TCC_API_URL}/me`;

  const aclService = new ACLService({
    acl_endpoint: TM_ACL_ENDPOINT,
    jwtToken: tokens.id_token
  })

  return (
    <ACLProvider aclService={aclService}>
      <BrowserRouter>
        <Routes>
          <Route path='/:app'>
            <Route path='' element={<Home />} />
            <Route path='language/:lang' element={<SetLanguage />} />
          </Route>

          <Route path='*' element={<WrappedComponent />} />
        </Routes>
      </BrowserRouter>
    </ACLProvider>
  )
}

const WrappedComponent: React.FC = () => {
  const [search] = useSearchParams()
  const { Revalidate } = useAuth()

  const tokenExpired = search.get('expire')

  if (tokenExpired) {
    Revalidate()
  }

  let redirect = '/its'
  const platform = localStorage.getItem('redirect_after_login')

  if (platform) {
    redirect = platform
  }

  return <Navigate to={redirect} replace />
}

export default AuthorizedRoutes
