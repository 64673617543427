import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import Authorize from '../pages/Authorize'
import Login from '../pages/Login'

const SignRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/authorize' element={<Authorize />} />

        <Route path='/' element={<Login />} />

        <Route path='*' element={<WrappedComponent />} />
      </Routes>
    </BrowserRouter>
  )
}

const WrappedComponent: React.FC = () => {
  const platform = window.location.pathname

  if (['/tcc', '/its'].includes(platform)) {
    localStorage.setItem('redirect_after_login', platform)
  }

  return <Navigate to='/' replace />
}

export default SignRoutes