import React, { useEffect } from 'react'
import { useAuth } from '../../contexts/auth'

const Login: React.FC = () => {
  const { Login } = useAuth()

  useEffect(() => { Login() }, [Login])

  return (
    <p>
      Not logged in, redirecting...
    </p>
  )
}

export default Login