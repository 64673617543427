import { Navigate, useParams } from "react-router-dom";

function SetLanguage() {
  let { lang, app } = useParams();

  localStorage.setItem('language', lang || 'en');

  return <Navigate to={`/${app}`} />
}

export default SetLanguage;
