import React, { createContext, ReactNode, useContext } from 'react'
import { useAuth as useAuthPKCE } from 'react-oauth2-pkce'
import { EnvironmentService } from '../libs/environment'

interface AuthProviderProps {
  children: ReactNode
}

interface AuthProviderData {
  signed: boolean
  Login: () => void
  Logout: () => void
  Revalidate: () => void
  tokens: {
    access_token: string
    created_at: number
    expires_at: number
    expires_in: number
    id_token: string
    refresh_token: string
    scope: string
    token_type: string
  }
}

const AuthContext = createContext({} as AuthProviderData)

const {
  ASSO_URL,
  PCRQ_URL,
} = EnvironmentService.getVars();

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }: AuthProviderProps) => {
  const { authService } = useAuthPKCE()

  function Login() {
    authService.authorize()
  }

  function Logout() {
    const assoLogoutUrl = `${ASSO_URL}/single_sign_out?redirect_uri=${PCRQ_URL}`

    authService.logout(true).then(() => window.location.href = assoLogoutUrl)
  }

  function Revalidate() {
    authService.logout(false).then(() => authService.authorize())
  }

  const tokens = authService.getAuthTokens()
  const signed = authService.isAuthenticated()

  return (
    <AuthContext.Provider value={{ signed, Login, Logout, Revalidate, tokens } as AuthProviderData}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)

  return context
}

export default AuthContext
