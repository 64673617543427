import { IEnvironmentValues } from '.';

const envVars: IEnvironmentValues = {
  ITS_APP_URL: 'https://intelmate.net',
  ITS_API_URL: 'https://api.gtlcommand.com/its',
  SNAP_APP_URL: 'https://snap.telmate.com',
  SNAP_API_URL: 'https://api.gtlcommand.com/snap',
  TCC_API_URL: 'https://api.gtlcommand.com/tcc',
  TCC_APP_URL: 'https://command-center.telmate.com',
  TMTS_API_URL: 'https://api.gtlcommand.com/tmts',
  PCRQ_URL: 'https://pcrq.gtlcommand.com',
  ASSO_URL: 'https://sso.gtlcommand.com',
  AUTH_CLIENT_ID: '24G1ZyaNAZoswlqcOITuQCkhPNmfpY6bFzP2urT3jp8',
  ENV: 'prod'
}

const hosts: string[] = ['pcrq.gtlcommand.com', 'pcrq.gtl.sh']

export const prod = {
  envVars,
  hosts
}