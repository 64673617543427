import React from 'react'

import { AuthProvider as AuthProviderPKCE, AuthService } from 'react-oauth2-pkce'

import Routes from './routes'
import { AuthProvider } from './contexts/auth'
import { EnvironmentService } from './libs/environment'

const {
  AUTH_CLIENT_ID,
  ASSO_URL,
  PCRQ_URL,
} = EnvironmentService.getVars()

const PROVIDER = `${ASSO_URL}/oauth`
const REDIRECT_URI = `${PCRQ_URL}/authorize`
const SCOPES = 'openid,profile,email'

const authService = new AuthService({
  clientId: AUTH_CLIENT_ID || '',
  provider: PROVIDER || '',
  redirectUri: REDIRECT_URI,
  scopes: SCOPES?.split(',') || ['openid'],
  location: window.location,
  logoutEndpoint: `${ASSO_URL}/users/sign_out`
})

const App: React.FC = () => {
  return (
    <AuthProviderPKCE authService={authService}>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </AuthProviderPKCE>
  )
}

export default App
