import React from 'react'

import SignRoutes from './SignRoutes'
import AuthorizedRoutes from './AuthorizedRoutes'

import { useAuth } from '../contexts/auth'

const Routes: React.FC = () => {
  const { signed } = useAuth()

  return signed ? <AuthorizedRoutes /> : <SignRoutes />
}

export default Routes