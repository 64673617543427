import { local } from './local'
import { prod } from './prod'
import { qa } from './qa'

export type IEnvironmentValues = {
  ITS_APP_URL: string
  ITS_API_URL: string
  SNAP_APP_URL: string
  SNAP_API_URL: string
  TCC_API_URL: string
  TCC_APP_URL: string
  TMTS_API_URL: string
  PCRQ_URL: string
  ASSO_URL: string
  AUTH_CLIENT_ID: string
  ENV: string
}

const ENVIRONMENTS: { [environment: string]: IEnvironmentValues } = {
  local: local.envVars,
  qa: qa.envVars,
  production: prod.envVars,
}

const ENVIRONMENT_HOSTS: { [environment: string]: Array<string> } = {
  local: local.hosts,
  qa: qa.hosts,
  production: prod.hosts,
}

export class EnvironmentService {
  static getVars(): IEnvironmentValues {
    let envName = 'production'
    let { hostname } = window.location

    for (let environment in ENVIRONMENT_HOSTS) {
      if (ENVIRONMENT_HOSTS[environment].includes(hostname)) {
        envName = environment
        break
      }
    }

    console.info(`[ENVIRONMENT] using ${envName}...`)

    return ENVIRONMENTS[envName]
  }
}
