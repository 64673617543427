import i18n from "i18next";
import React, { ReactNode, useEffect } from 'react';
import { initReactI18next } from "react-i18next";
import { useACL } from '../contexts/acl';
import { useAuth } from '../contexts/auth';
import { isITS, isTCC } from '../hooks/use-platform';
import { EnvironmentService } from "../libs/environment";
import { translations } from "../translations";

interface DefaultTemplateProps {
  children: ReactNode
}

const {
  ITS_API_URL,
  SNAP_API_URL,
  TCC_API_URL,
  PCRQ_URL,
  ASSO_URL,
  ENV,
} = EnvironmentService.getVars()

i18n
  .use(initReactI18next)
  .init({
    resources: translations,
    lng: localStorage.getItem('language') || 'en',
    fallbackLng: 'en',
    interpolation: { escapeValue: false }
  });

const DefaultTemplate: React.FC<DefaultTemplateProps> = ({ children }) => {
  const { tokens: { id_token: token }, Logout } = useAuth()
  const { clear } = useACL()

  function startGlobalNav(navigation: any) {
    // @ts-ignore
    window.GlobalNavController?.render(
      { sidebar: 'sidebar' },
      {
        appNameSpace: 'PCRQ',
        locale: 'en',
        apiUrls: {
          navigation,
          layout_data: `${ITS_API_URL}/itsapi/navapi/layout_data_json?token=${token}`,
          modals: {
            url: '/tmp/modals-qa.json'
          },
          logout: '/',
          after_logout: '/',
          facility_switch: `${ITS_API_URL}/itsapi/navapi/switch_facility?token=${token}`,
          asso: {domain: ASSO_URL}
        },
        userId: token,
        isAssoUser: true,
        header: {
          disableDefaultLogout: true,
          profileItems: [
            {
              title: 'My Profile',
              onClick: () => window.open(`${ASSO_URL}/profile`, '_blank')
            },
            {
              title: 'Sign Out',
              onClick: () => {
                clear()
                Logout()
              }
            }
          ]
        },
        footer: {}
      }
    )
  }

  useEffect(() => {
    let navigation = {}

    const defaultITS = !isITS() && !isTCC()

    // load ITS by default
    if (isITS() || defaultITS) {
      navigation = {
        'ITS': {
          'url': `${ITS_API_URL}/itsapi/navapi/nav_json`,
          'token': token,
        },
        'PCRQ': {
          'url': `${PCRQ_URL}/${ENV}/nav-its.json`,
          'token': token,
        },
        'SNAP': {
          'url': `${SNAP_API_URL}/snapi/nav_json`,
          'token': token,
        },
      }

    } else if (isTCC()) {
      navigation = {
        'TCC': {
          'url': `${TCC_API_URL}/api/navapi/nav_json`,
          'token': token,
        },
        'PCRQ': {
          'url': `${PCRQ_URL}/${ENV}/nav-tcc.json`,
          'token': token,
        },
      }
    }

    setTimeout(() => {
      startGlobalNav(navigation);
    }, 300);
  }, [Logout, token, startGlobalNav])

  return (
    <div style={{ paddingLeft: 40 }}>
      {children}
    </div>
  )
}

export default DefaultTemplate
